import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
// import { Link } from "gatsby"
// import { addSlashButtonLink } from '../../helper'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const EngineersAndITData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const data = [
          {
               title: 'No-code, Fully Customizable',
               content:
                    'ListenLayer automates all data generation and collection for marketing, including things that would typically require custom code. The marketing team can write powerful, visual rules that enrich and segment their data as needed - no programming skills required.',
          },
          {
               title: 'Non-Technical Implementation',
               content:
                    'Marketing can implement ListenLayer without requiring IT and development resources. Deep integrations and advanced customization may benefit from minor updates to your code-base.',
          },
          {
               title: 'Security-first Architecture',
               content:
                    'ListenLayer is currently pursuing SOC2, Type 2 compliance and executes regular security and penetration testing. Our architecture supports GDPR, CCPA/CPRA, and other regulatory requirements.',
          },
          {
               title: 'Consent & Compliance Management',
               content:
                    'ListenLayer is consent-first. The core architecture of how we identify users and store data focuses on enabling compliance with ever-changing privacy laws. ListenLayer can replace, or augment, your current CMP tool for simplicity and savings.',
          },
          {
               title: '100% First Party, Server Side',
               content:
                    'Implement server side tracking with the flip of a switch. Utilize your own sub domain to achieve a 100% first-party data strategy that boosts your website speed and data accuracy.',
          },
          {
               title: 'The Future of Cookieless',
               content: 'ListenLayer offers a cookieless architecture to future-proof your measurement strategy. The future is already here!',
          },
          {
               title: 'Google Analytics Alternative',
               content:
                    "Break away from Google's stack while receiving even better data and powering what marketing requires. Your data belongs to you (not Google), take it back!",
          },
          {
               title: 'World Class Support',
               content:
                    'Your marketing team can lean on our world class support to fill knowledge gaps and ensure a successful implementation - while minimizing time investment from IT and development resources!',
          },
     ]

     const pageName = 'Engineers and IT'
     const breadcrumbItems = [
          {
               name: 'Outcomes',
               url: `${'outcomes'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.search.substring('?view-demo=')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="custom-centralized-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro custom-platform-measurement-pros">
                    <div className="circle-green"></div>
                    <div className="circle-blue"></div>
                    <div className="circle-yellow"></div>
                    <div className="container">
                         <div className="platform-intro-title">
                              <b className="platform-intro-des">Developers & IT Professionals</b>
                              <h1 className="mt-3">Enable Modern, Future-Proofed Marketing Analytics</h1>
                              <b className="platform-intro-str">With Minimal Involvement from Your Team</b>
                              <p className="mt-2" style={{ width: '90%' }}>
                                   Your job is to solve problems. With ListenLayer, you'll bring an innovative solution that is compliant, secure,
                                   ready for the future, and fulfills marketing's needs without writing custom code and creating tech-debt.
                              </p>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step custom-box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-6 mb-6 mt-3">
                                                  <div className="card-step">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-desc">{item.content}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
                    <SectionBoxReady
                         title={'Get Started'}
                         content={
                              'See how easy (and powerful) ListenLayer is by opening an account today. Implement yourself or connect with our team for a full Demo.'
                         }
                         style={'40px'}
                    />
               </section>
          </Layout>
     )
}

export default EngineersAndITData
